import { StoreKey } from "@/const";
import { AuthActions } from "@/redux/features/auth-slice";
import { useAppSelector } from "@/redux/store";
import { client_proto } from "@/utils/net/shared/proto";
import { StorageData } from "@/utils/net/storage/StorageData";
import { Dispatch } from "@reduxjs/toolkit";
import { useRouter } from "next/navigation";
import { useDispatch } from "react-redux";

// 不需要校验登录态的路由
export const notRequiredLoginRouters: { name: string; path: string }[] = [
  // {
  //     name:"home",
  //     path:"/"
  // },
  // {
  //     name:"vip",
  //     path:"/vip"
  // }
];

/**
 * 判断是否是游客模式
 * @param userBasic
 * @returns
 */
export const checkIsGuest = (loginType: number, isStrict: boolean = false) => {
  if (isStrict) {
    return loginType === 1;
  }
  return loginType === 1 || loginType === 0;
};

export const requiredLogin = (loginType: number, pathname: string) => {
  if (checkIsGuest(loginType, true)) {
    //未绑定手机号，默认为伪游客
    return notRequiredLoginRouters.some((item) => item.path === pathname);
  }
  return false;
};

//拦截未登录的点击
export const useLoginInterceptor = () => {
  const loginType = useAppSelector((state) => state.authSlice.loginType);
  const dispatch = useDispatch();
  return {
    linkInterceptor: (e: React.MouseEvent<HTMLAnchorElement | HTMLDivElement | HTMLElement>) => {
      if (checkIsGuest(loginType)) {
        e.preventDefault();
        dispatch(AuthActions.setTouristModal(true));
        return false;
      }
      return true;
    },
    callbackInterceptor: (callback: () => void, fail?: () => void) => {
      if (checkIsGuest(loginType)) {
        dispatch(AuthActions.setTouristModal(true));
        fail?.();
        return false;
      }
      callback();
    },
    isGuest: checkIsGuest(loginType),
  };
};
