import cc_dt_lh from "@/assets/home/cc_dt_lh.inline.png";
import cc_dt_sj from "@/assets/home/cc_dt_sj.inline.png";
import cc_yj_an from "@/assets/home/cc_yj_an.inline.png";
import cc_yj_jb from "@/assets/home/cc_yj_jb.inline.png";
import dadi from "@/assets/home/dadi.inline.png";
import icon_fz from "@/assets/home/icon_fz.inline.png";
import icon_lc from "@/assets/home/icon_lc.inline.png";
import icon_phb from "@/assets/home/icon_phb.inline.png";
import icon_sc from "@/assets/home/icon_sc.inline.png";
import icon_vip from "@/assets/home/icon_vip.inline.png";
import icon_vip_sj from "@/assets/home/icon_vip_sj.inline.png";
import icon_zp from "@/assets/home/icon_zp.inline.png";
import tubiao1 from "@/assets/home/tubiao1.inline.png";
import tubiao2 from "@/assets/home/tubiao2.inline.png";
import jiantou from "@/assets/home/jiantou.inline.png";
import gb_anniu from "@/assets/common/gb_anniu.inline.png";
import gx_01 from "@/assets/common/gx_01.inline.png";
import gx_02 from "@/assets/common/gx_02.inline.png";
import kf from "@/assets/common/kf.inline.png";
import jgan from "@/assets/common/jgan.inline.png";
import tx_en from "@/assets/home/tx.inline.png";
import wx_en from "@/assets/home/tx-2.inline.png";
import jb_en from "@/assets/home/jb.inline.png";
import topone_cc_yj_an from "@/assets/home/topone_cc_yj_an.inline.png";
import topone_icon_phb from "@/assets/home/topone_icon_phb.inline.png";
import djs_di from "@/assets/home/djs_di.inline.png";
import icon_ir from "@/assets/home/icon_ir.inline.png";

export const pngList = {
  cc_dt_lh,
  cc_dt_sj,
  cc_yj_an,
  cc_yj_jb,
  dadi,
  icon_fz,
  icon_lc,
  icon_phb,
  icon_sc,
  icon_vip,
  icon_vip_sj,
  icon_zp,
  tubiao1,
  tubiao2,
  jiantou,
  gb_anniu,
  gx_01,
  gx_02,
  kf,
  jgan,
  tx_en,
  wx_en,
  jb_en,
  topone_cc_yj_an,
  topone_icon_phb,
  djs_di,
  icon_ir,
};
