"use client";
import { logo_min, topOne_logo_main } from "@/const/svg";
import classNames from "classnames";
import "intersection-observer";
import { useEffect, useMemo, useRef, useState } from "react";
import Img from "../Img";
import { useImgType } from "@/hooks/user";
import { useLoadingImage } from "@/hooks/image";
import { cdnPrefix } from "@/utils";
import { StaticImageData } from "next/image";

interface LazyImageProps {
  // src: string | StaticImageData;
  src: string;
  height: number | string;
  className?: string;
  alt?: string;
  divProps?: {
    className?: string;
    dynamiClassName?: string;
  };
  logo?: string | StaticImageData;
  onLoad?: () => void;
  loadedClassName?: string;
  style?: React.CSSProperties;
  isTO?: boolean;
  toUrl?: string;
  isSwiper?: boolean; // 针对object-fit:cover 和object-fit:contain 属性
}

function LazyImage(props: LazyImageProps) {
  const { type } = useImgType();
  const {
    src,
    height,
    logo = "",
    loadedClassName,
    style,
    className,
    alt,
    isTO = false,
    isSwiper = false,
    ...restProps
  } = props;
  const defaultLogo = logo === "" ? (type ? topOne_logo_main : logo_min) : logo;
  const [isIntersecting, setIsIntersecting] = useState(false);
  const [imageUrl, setImageUrl] = useState(defaultLogo);

  const [isScale, setIsScale] = useState(true);

  const imgRef = useRef<HTMLImageElement | null>(null);
  let imgUrl = src;
  if (isTO && type) {
    if (src?.indexOf("base64") !== -1) {
      imgUrl = restProps?.toUrl!;
    } else {
      let url = src?.split(process.env.NEXT_PUBLIC_CDN_RUL!)?.[1];
      imgUrl = process.env.NEXT_PUBLIC_CDN_RUL + "topOne/" + url;
    }
  }
  const [targetUrl] = useLoadingImage(imgUrl);

  useEffect(() => {
    if (targetUrl) {
      setImageUrl(targetUrl);
      setIsScale(false);
      setIsIntersecting(true);
    }
  }, [targetUrl]);

  const memoClassNames = useMemo(() => {
    return classNames(
      "flex justify-center items-center",
      {
        "bg-[#3c1418]": !isIntersecting,
        "bg-opacity-[0.8]": !isIntersecting,
        "light-shadow": !isIntersecting,
      },
      !isIntersecting && props.divProps?.dynamiClassName,
      props.divProps?.className,
    );
  }, [isIntersecting, props.divProps]);

  return (
    <div
      className={classNames([memoClassNames, !isIntersecting && "rounded-[2px]"])}
      style={{
        minHeight: `${height}`,
      }}
    >
      <Img
        ref={imgRef}
        className={classNames(
          "max-h-full max-w-full",
          {
            "w-[1.1rem]": !isIntersecting,
          },
          {
            "p-[10%]": isScale,
          },
          className,

          isIntersecting ? loadedClassName : "",
        )}
        src={imageUrl}
        alt={alt}
        importance="low"
        style={{
          ...style,
          objectFit: isSwiper && defaultLogo !== imageUrl ? "cover" : "contain",
        }}
        onLoad={() => {
          if (targetUrl === imageUrl) {
            props.onLoad?.();
          }
        }}
        {...restProps}
      />
    </div>
  );
}

export default LazyImage;
