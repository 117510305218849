"use client";

import { memo, useState, useEffect } from "react";
import { useInterval, useMount } from "react-use";

// 毫秒倒计时
const EndMilliseconds = memo(() => {
  const [milliseconds, setMilliseconds] = useState(1000);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setMilliseconds(milliseconds <= 0 ? 1000 : milliseconds - 100);
    }, 100);

    return () => clearInterval(intervalId); // 清理定时器
  }, [milliseconds]);

  return <span className="w-[.4rem] inline-block">0{milliseconds.toString().substring(0, 1)}</span>;
});

EndMilliseconds.displayName = "EndMilliseconds";

const EndTime = memo(
  ({ time, showMilliseconds, endCall }: { time: number; showMilliseconds?: boolean; endCall?: () => void }) => {
    const [t, setT] = useState<number | null>(null);

    useMount(() => {
      if (t === null && time) {
        setT(time);
      }
    });

    useEffect(() => {
      const intervalId = setInterval(
        () => {
          if (t !== null && t > 0) {
            setT(t - 1);
          }
        },
        t !== null && t > 0 ? 1000 : undefined,
      );

      return () => clearInterval(intervalId); // 清理定时器
    }, [t]);

    let hours: any = Math.floor(t! / 3600); // 小时
    let minutes: any = Math.floor((t! % 3600) / 60); // 分钟
    let seconds: any = Math.floor((t! % 60) % 60); // 秒

    if (t !== null && t! <= 0) {
      endCall?.();
    }

    if (hours === 0 && showMilliseconds) {
      return (
        <>
          {minutes < 10 ? "0" + minutes : minutes}:{seconds < 10 ? "0" + seconds : seconds}:
          {t! <= 0 ? "00" : <EndMilliseconds />}
        </>
      );
    }

    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`;
  },
);

EndTime.displayName = "EndTime";

export default EndTime;
