import { AttachmentState, MAX_ATTACHMENT_DAY, ReadState, MAX_MAIL_COUNT } from "@/const";
import { i18n, systemConfigUtils } from "@/utils";
import { timeRemaining } from "@/utils/dayjs";
import { client_proto } from "@/utils/net/shared/proto";
import { get } from "lodash-es";
import { EmailService } from "@/service";
import { StorageData } from "@/utils/net/storage/StorageData";
import { StoreKey } from "@/const";

const _isObject = (obj: string | null | undefined) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};
export const getMailContent = (mailData: client_proto.IMailInfo | undefined) => {
  if (!mailData) {
    return {};
  }
  if (_isObject(mailData.content)) {
    return mailData.content;
  }
  try {
    return mailData.content ? JSON.parse(mailData.content) : "";
  } catch (e) {
    console.log("解析邮件内容失败：", e);
    return {};
  }
};

//计算邮件过期天数
export const getOutDays = (mailData: client_proto.IMailInfo) => {
  let currTime = systemConfigUtils.delayTime;
  const { receive_time } = mailData;
  let txt = "";
  if (!receive_time) {
    return "";
  }
  if (receive_time - currTime / 1000 < 0) {
    return "";
  }
  let ms = receive_time * 1000 - currTime;
  let endTime = timeRemaining(ms);
  let outDays = get(endTime, "day") as number;
  let outHours = get(endTime, "hours") as number;
  let minutes = get(endTime, "minutes") as number;
  if (outDays) {
    return i18n.notfiy("EMAIL_EXPIRE_DAYS", outDays);
  }
  if (outHours > 0) {
    return i18n.notfiy("EMAIL_EXPIRE_HOURS", outHours);
  }
  if (minutes > 0) {
    return i18n.notfiy("EMAIL_EXPIRE_MINUTES", minutes);
  }
  return txt;
};
//检查邮件是否已经过期
export const checkIsOutTime = (mailData: client_proto.IMailInfo) => {
  let currTime = (systemConfigUtils.delayTime || Date.now()) / 1000;
  if (!mailData.receive_time) {
    return false;
  }
  //当前时间-接收时间 >= 最大时间 则失效
  if (currTime - mailData.receive_time < 24 * 60 * 60 * MAX_ATTACHMENT_DAY) {
    return false;
  }
  return true;
};
export const getAttachment = (mailData: client_proto.IMailInfo | undefined) => {
  if (!mailData) {
    return {};
  }
  if (_isObject(mailData.attachment)) {
    return mailData.attachment || {};
  }
  try {
    return mailData.attachment ? JSON.parse(mailData.attachment) : {};
  } catch (e) {
    console.log("解析邮件内容失败：", e);
    return {};
  }
};

// 计算未读邮件
export const countUnRead = (mailList: client_proto.IMailInfo[]) => {
  let typeConf: any = {
    all: 0,
  };

  typeConf.all = 0;
  //先分类型
  for (let emailID in mailList) {
    if (Object.prototype.hasOwnProperty.call(mailList, emailID)) {
      let oneEmail = mailList[emailID];
      let attachment = getAttachment(oneEmail);
      if (oneEmail.mail_type && typeConf[oneEmail.mail_type] == null) {
        typeConf[oneEmail.mail_type] = 0;
      }
      if (checkIsOutTime(oneEmail) == false) {
        if (attachment?.items?.length > 0) {
          //附件
          //未读 未领取
          if (oneEmail.attachment_state == AttachmentState.None) {
            typeConf[oneEmail.mail_type!] = typeConf[oneEmail.mail_type!] + 1;
            typeConf["all"] = typeConf["all"] + 1;
          }
        } else {
          //非附件
          if (oneEmail.read_state == ReadState.None) {
            typeConf[oneEmail.mail_type!] = typeConf[oneEmail.mail_type!] + 1;
            typeConf["all"] = typeConf["all"] + 1;
          }
        }
      }
    }
  }
  return typeConf;
};
//检测附件是否有效
export const getAttachmentExpiredDays = (expired_time: number, receive_time: number) => {
  let expiredDays = false;
  let time = expired_time;
  if (time <= 0) {
    time = receive_time + 24 * 60 * 60 * MAX_ATTACHMENT_DAY;
  }
  if (String(time).length == 10) {
    let currTime = (systemConfigUtils.delayTime || Date.now()) / 1000;
    if (Number(time) - currTime > 0) {
      expiredDays = true;
    }
  }
  return expiredDays;
};

//判断是否有附件
export const hasAttachment = (attachmentStr?: string | null) => {
  if (!attachmentStr) {
    return false;
  }
  let attachment = { items: [] };
  try {
    attachment = JSON.parse(attachmentStr);
  } catch (error) {}
  return attachment?.items?.length > 0;
};

//查找已读邮件id
export const getReadMailIds = (mailList: client_proto.IMailInfo[] | undefined) => {
  const ids: number[] = [];
  if (mailList === undefined) {
    return ids;
  }
  for (let index = 0; index < mailList.length; index++) {
    const mailData = mailList[index];
    let attachment = { expired_time: 0 };

    try {
      attachment = mailData.attachment ? JSON.parse(mailData.attachment) : {};
    } catch (e) {}

    if (mailData.read_state === ReadState.Readed) {
      if (!hasAttachment(mailData.attachment)) {
        //非附件已读
        ids.push(mailData.mail_id!);
      } else {
        if (!getAttachmentExpiredDays(attachment?.expired_time, mailData?.receive_time!)) {
          //附件已经过期了
          ids.push(mailData.mail_id!);
        } else if (mailData.attachment_state === AttachmentState.Received) {
          //附件已经领取
          ids.push(mailData.mail_id!);
        }
      }
    } else {
      if (!hasAttachment(mailData.attachment)) {
        //非附件已过期
        if (checkIsOutTime(mailData)) {
          ids.push(mailData.mail_id!);
        }
      } else if (!getAttachmentExpiredDays(attachment?.expired_time, mailData?.receive_time!)) {
        ids.push(mailData.mail_id!);
      } else if (mailData?.attachment_state === AttachmentState.Received) {
        //附件已经领取
        ids.push(mailData.mail_id!);
      }
    }
  }
  return ids;
};

// 查询tab的下的可领取附件
export const getAttachmentList = (mailList: client_proto.IMailInfo[]) => {
  let res = [];

  for (let i = 0; i < mailList.length; i++) {
    let emailData = mailList[i];
    const attachment = getAttachment(emailData);
    if (
      getAttachmentExpiredDays(attachment?.expired_time, emailData.receive_time!) &&
      hasAttachment(emailData?.attachment)
    ) {
      if (emailData.attachment_state != AttachmentState.Received) {
        res.push(emailData.mail_id);
      }
    } else {
      // console.log("附件无效", emailData);
    }
  }
  // console.log("获取可领取附件id列表:1", res);
  return res;
};

// 查询tab的下的未读邮件
export const getNoReadList = (mailList: client_proto.IMailInfo[]) => {
  let res = [];

  for (let i = 0; i < mailList.length; i++) {
    let emailData = mailList[i];
    if (!checkIsOutTime(emailData) && !hasAttachment(emailData?.attachment)) {
      if (emailData.read_state !== ReadState.Readed) {
        res.push(emailData.mail_id);
      }
    } else {
      // console.log("邮件无效", emailData);
    }
  }
  return res;
};

export const parseQueryString = (queryString: string): { [key: string]: string } => {
  const params = queryString.replace(/^\?/, "").split("&");
  const result: { [key: string]: string } = {};

  for (const param of params) {
    const [key, value] = param.split("=");
    result[key] = decodeURIComponent(value || "");
  }

  return result;
};

export const parseJumpData = (str: string) => {
  const params = {
    gt_page: "",
    gt_params: "",
  };
  const data = parseQueryString(str);
  params.gt_page = data.gt_page;
  params.gt_params = str.substring(str.indexOf(data.gt_params));
  return params;
};

export const formatWithCommas = (number: number) => {
  return number?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

// 根据活动id查找邮件
export const getEmailIDListByActivityID = (
  activityID: number | string,
  mailList: client_proto.IMailInfo[],
  ignoreExipred?: boolean,
): Array<number> => {
  let emailIDList: Array<number> = [];
  activityID = Number(activityID);
  for (let i = 0; i < mailList.length; i++) {
    let oneEmail = mailList[i];
    if (oneEmail && oneEmail.attachment) {
      let attachment = {};
      try {
        attachment = JSON.parse(oneEmail.attachment);
      } catch (e) {
        attachment = {};
      }
      if (get(attachment, "activity_id") == activityID) {
        if (hasAttachment(oneEmail.attachment)) {
          let v = oneEmail;
          if (v.read_state == ReadState.None) {
            if (v.attachment_state == AttachmentState.None) {
              //未读未领取
              emailIDList.push(oneEmail.mail_id!);
            } else {
              //未读已领取
            }
          } else if (v.read_state == ReadState.Readed) {
            if (v.attachment_state == AttachmentState.None) {
              //是否需要过滤过期邮件
              if (ignoreExipred) {
                if (getAttachmentExpiredDays(get(attachment, "expired_time", 0), v.receive_time!)) {
                  emailIDList.push(oneEmail.mail_id!);
                }
              } else {
                //已读未领取
                emailIDList.push(oneEmail.mail_id!);
              }
            } else {
              //已领取或过期
            }
          }
        }
      }
    }
  }
  return emailIDList;
};

/** 检查是否需要请求新邮件 */
export const checkHasNewEmail = (localMaxEmailID: number, serverMaxEmailID: number, serverMinEmailID: number) => {
  // log("检查是否需要请求新邮件=>最大ID", this.localMaxEmailID, this.serverMaxEmailID)

  if (localMaxEmailID !== serverMaxEmailID) {
    StorageData.local.set(StoreKey.EMAIL_MIN_ID, serverMinEmailID);
    StorageData.local.set(StoreKey.EMAIL_MIX_ID, serverMaxEmailID);
    //最小比最大大 说明没有邮件 不用请求
    if (serverMinEmailID > serverMaxEmailID) {
      return false;
    }
    return true;
  }
  return false;
};

/** 获取区间循环次数 */
export const getSpanceForNum = (
  serverMaxEmailID: number,
  serverMinEmailID: number,
  localMaxEmailID: number,
  isReal = false,
) => {
  let num = 0;
  if (isReal) {
    num = Math.round((serverMaxEmailID - serverMinEmailID) / MAX_MAIL_COUNT);
    if (serverMaxEmailID != serverMinEmailID && num == 0) {
      num = 1;
    }
  } else {
    num = Math.round((serverMaxEmailID - localMaxEmailID) / MAX_MAIL_COUNT);
    if (serverMaxEmailID != localMaxEmailID && num == 0) {
      num = 1;
    }
  }
  if (num < 0) {
    num = 0;
  }
  return num;
};

// 请求邮件数据
export const updataMail = (max_mail_id: number, min_mail_id: number, uid: number) => {
  const max = StorageData.local.get(StoreKey.EMAIL_MIX_ID, 1);
  if (checkHasNewEmail(Number(max), max_mail_id!, min_mail_id!)) {
    let num = getSpanceForNum(max_mail_id!, min_mail_id!, Number(max));
    let min = Number(max);
    for (let i = 0; i < num; i++) {
      let max = min + MAX_MAIL_COUNT;
      EmailService.reqMailList(uid!, min, max);
      min = max + 1;
    }
  }
};

// 处理返回分段邮件数据
// export const
