import { getBackgroundUrl } from "@/utils";
import { useEffect, useState } from "react";

export function useLoadingImage(image: string) {
  const [src, setSrc] = useState("");
  const [url, setUrl] = useState("");

  function load(src: string): Promise<string> {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => resolve(src);
      img.onerror = reject;
      img.src = src;
    });
  }

  useEffect(() => {
    if (process.env.NODE_ENV === "development" || !image.startsWith(process.env.NEXT_PUBLIC_CDN_RUL!)) {
      setSrc(image);
      setUrl(`url(${image})`);
      return;
    }

    if (image) {
      const fileUrl = image.replace(process.env.NEXT_PUBLIC_CDN_RUL!, "");
      const urls = getBackgroundUrl(fileUrl);
      if (urls.length > 1) {
        const [avif, webp, png] = urls;
        load(avif)
          .catch(() => load(webp))
          .catch(() => load(png))
          .then((img) => {
            setSrc(img);
            setUrl(`url(${img})`);
          });
      } else {
        setSrc(image);
        setUrl(`url(${image})`);
      }
    }
  }, [image]);

  return [src, url];
}
