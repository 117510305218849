import React from "react";
import classNames from "classnames";
import "./Button.scss";

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  styleType?: "default" | "red" | "yellow" | "black";
}

const Button: React.FC<ButtonProps> = ({ children, className, styleType = "default", ...props }) => {
  const typeClassMap: { [key: string]: string } = {
    default: "common-default-button",
    red: "common-button-red",
    yellow: "common-button-yellow",
    black: "common-button-black",
  };
  const buttonClass = typeClassMap[styleType];
  return (
    <button className={classNames("btn_animation", buttonClass, className)} {...props}>
      {children}
    </button>
  );
};

export default Button;
